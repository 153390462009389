import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBVgNViMqx3Lnbuo6bYJ3_-gKiXzTwpZpI",
  authDomain: "account.fuer-gruender.de",
  projectId: "fuer-gruender-account",
  storageBucket: "fuer-gruender-account.appspot.com",
  messagingSenderId: "878406810077",
  appId: "1:878406810077:web:a80c5f207720bc48349e34",
  measurementId: "G-8SGE8M5F8C",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

(async () => {
  await setPersistence(auth, browserLocalPersistence);
})();
