import { useEffect, useMemo, useState } from "react";
import useFirebaseAuthentication from "../hooks/useFirebaseAuthentication";
import { Typography } from "@mui/material";
import useSubscription from "../hooks/useSubscription";
import useApi from "../hooks/useApi";

const products = [
  {
    id: "marketingbooster",
    pricingTableId: "prctbl_1Pn2r0Hi2dpU1Gup2KyQLVOc",
    // eslint-disable-next-line max-len
    publishableKey: "pk_test_51PKxrqHi2dpU1GupImVdg8ItWRPeR9qNb6kTm1hJ9GSvxWWaGiqmCvaLuVzD2r8yOvQb9xKczBnorcCbR9CWa32X0053Jf4gjj",
  },
  {
    id: "community",
    pricingTableId: "prctbl_1Pq8XjHi2dpU1GupgHSmShnA",
    // eslint-disable-next-line max-len
    publishableKey: "pk_test_51PKxrqHi2dpU1GupImVdg8ItWRPeR9qNb6kTm1hJ9GSvxWWaGiqmCvaLuVzD2r8yOvQb9xKczBnorcCbR9CWa32X0053Jf4gjj",
  },
];

// Example: https://account.fuer-gruender.de/subscribe?product=marketingbooster

// We can start a timer, and after 30 minutes, we need to display that the session has expired
// as customer sessions are valid for 30 minutes:
// https://docs.stripe.com/payments/checkout/pricing-table#customer-session

const SubscribePage = () => {
  const { currentUser, idToken } = useFirebaseAuthentication();
  const { userProducts, hasBilling } = useSubscription();
  const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState<string | null>();
  const { jsonApiPost } = useApi();
  const productId = new URLSearchParams(window.location.search).get("product");

  const userAlreadySubscribedToSelectedProduct = useMemo<boolean>(
    () => {
      return userProducts?.includes(productId ?? "unknown") ?? false;
    },
    [productId, userProducts]);


  let selectedProduct = null;
  if (productId) {
    selectedProduct = products.find((product) => product.id === productId);
  }

  useEffect(() => {
    const getCustomerSessionClientSecret = async () => {
      if (hasBilling && idToken) {
        const customerSessionPayload = await jsonApiPost<{ customer_session_client_secret: string }>(
          "/api/subscription/customer-session", idToken);
        setCustomerSessionClientSecret(customerSessionPayload.customer_session_client_secret);
      }

      if (hasBilling === false) {
        setCustomerSessionClientSecret(null);
      }
    };
    getCustomerSessionClientSecret();
  }, [hasBilling, idToken]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!currentUser) {
    return <p>Loading...</p>;
  }

  if (userAlreadySubscribedToSelectedProduct) {
    return (
      <Typography variant="h1" component="h1" align="center" fontSize={"2rem"}>
        Du hast bereits ein Abonnement für dieses Produkt.
      </Typography>
    );
  }

  if (!selectedProduct) {
    return (
      <Typography variant="h1" component="h1" align="center" fontSize={"2rem"}>
        Produkt nicht gefunden.
      </Typography>
    );
  }

  if (customerSessionClientSecret) {
    return (
      <div>
        <Typography variant="h1" component="h1" align="center" fontSize={"2rem"}>
          Der passende Tarif für Dein Vorhaben
        </Typography>
        <stripe-pricing-table
          pricing-table-id={selectedProduct.pricingTableId}
          publishable-key={selectedProduct.publishableKey}
          client-reference-id={currentUser?.uid}
          customer-session-client-secret={customerSessionClientSecret}
        ></stripe-pricing-table>
      </div>);
  }

  if (customerSessionClientSecret === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Typography variant="h1" component="h1" align="center" fontSize={"2rem"}>
            Der passende Tarif für Dein Vorhaben
      </Typography>
      <stripe-pricing-table
        pricing-table-id={selectedProduct.pricingTableId}
        publishable-key={selectedProduct.publishableKey}
        customer-email={currentUser?.email}
        client-reference-id={currentUser?.uid}
      ></stripe-pricing-table>
    </div>
  );
};

export default SubscribePage;
