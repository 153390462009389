import { useState, useEffect, useMemo } from "react";
import useFirebaseAuthentication from "./useFirebaseAuthentication";
import useApi from "./useApi";

interface Subscription {
  hasBilling: boolean;
  packages: string[]; // e.g. "marketingbooster_basic_1m", "marketingbooster_advanced_1m", "marketingbooster_360_12m"
}

const useSubscription = () => {
  const { isAuthenticated, idToken } = useFirebaseAuthentication();
  const [userSubscription, setUserSubscription] = useState<Subscription | null>();

  const hasBilling = useMemo<boolean | undefined>(() => {
    return userSubscription?.hasBilling;
  }, [userSubscription]);

  const userPackages = useMemo<string[] | undefined>(() => {
    return userSubscription?.packages;
  }, [userSubscription]);

  const userProducts = useMemo<string[] | undefined>(() => {
    return userSubscription?.packages.map((p) => p.split("_")[0]);
  }, [userSubscription]);
  const { jsonApiGet } = useApi();

  useEffect(() => {
    if (isAuthenticated === undefined) {
      return;
    }
    if (isAuthenticated && idToken) {
      const fetchUserSubscription = async () => {
        try {
          const subscription = await jsonApiGet<Subscription>("/api/subscription", idToken);
          setUserSubscription(subscription);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUserSubscription(undefined);
        }
      };

      fetchUserSubscription();
    } else {
      setUserSubscription(null);
    }
  }, [isAuthenticated, idToken]);

  return { hasBilling, userProducts, userPackages };
};

export default useSubscription;
