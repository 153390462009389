/* eslint-disable require-jsdoc */
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import DashboardPage from "./pages/DashboardPage";
import { AuthProvider } from "./AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import AnonymousOnlyRoute from "./AnonymousOnlyRoute";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import SubscribePage from "./pages/SubscribePage";
import FeaturesPage from "./pages/FeaturesPage";
import ThankYouPage from "./pages/ThankYouPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<ProtectedRoute redirect="/login" />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Route>
        <Route element={<AnonymousOnlyRoute redirect="/" />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
